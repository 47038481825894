import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Linking, StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import {
  useBanksOfUserQuery,
  useUnlinkBankAccountMutation,
} from "../../../../generated/graphql";
import { logger } from "../../../../services/logger";
import { Text, View } from "../../../Themed";
import TranslatedText from "../../../TranslatedText";

export default function Connections() {
  const { t } = useTranslation();
  const [unlinkBankAccount] = useUnlinkBankAccountMutation({});
  const [loading, setLoading] = useState(false);
  const { data: banksOfUser, loading: loadingBanksOfUser } =
    useBanksOfUserQuery();

  if (!banksOfUser || loadingBanksOfUser) {
    return <Text>Loading ...</Text>;
  }

  const styles = StyleSheet.create({
    bank: {
      fontWeight: "bold",
      width: "100%",
      textAlign: "center",
      margin: 10,
    },
    account: { width: "100%", margin: 10 },
  });

  return (
    <View>
      {banksOfUser.banksOfUser.map((bank) => {
        return (
          <React.Fragment key={bank.id}>
            <Text style={styles.bank}>{bank.name}</Text>
            {bank.accounts.map((account) => {
              return (
                <>
                  <Text style={styles.account} key={account.id}>
                    {account.name}
                  </Text>
                </>
              );
            })}
            <Button
              onPress={async () => {
                unlinkBankAccount({
                  variables: {
                    budgetInsideClientId: bank.id,
                  },
                });
              }}
            >
              <TranslatedText>Supprimer</TranslatedText>
            </Button>
          </React.Fragment>
        );
      })}
      <Button
        loading={loading}
        disabled={loading}
        style={{ margin: 10 }}
        mode="contained"
        onPress={async () => {
          setLoading(true);
          logger.debug("new connection");
          const fullUrl = await Linking.getInitialURL();
          if (fullUrl) {
            const baseUrl = new URL(fullUrl);
            await Linking.openURL(
              `https://jta-money-sandbox.biapi.pro/2.0/auth/webview/?client_id=57165709&redirect_uri=${baseUrl.origin}/accounting/connect`
            );
            setLoading(false);
          }
        }}
      >
        <Text>{t("Nouvelle Connection")}</Text>
      </Button>
    </View>
  );
}
