import { StyleSheet } from "react-native";
import { Card, useTheme } from "react-native-paper";
import InnerBalances from "../../components/Accounts/Balance/Inner";
import BilanPreview from "../../components/Accounts/BilanPreview";
import InnerAccountsList from "../../components/Accounts/List/Inner";
import StatsPreview from "../../components/Accounts/StatsPreview";
import ErrorBoundary from "../../components/ErrorBoundary";
import { Text, View } from "../../components/Themed";
import { useAppNavigation } from "../../hooks/useAppNavigation";

export default function Accounts() {
  const navigation = useAppNavigation();
  const theme = useTheme();

  const styles = StyleSheet.create({
    accountListScrollView: {
      width: "100%",
      height: "100%",
    },
    transactionsListScrollView: {
      width: "100%",
      height: "38%",
    },
    // used by the web version
    accountListScrollViewScrollViewWrapper: {
      width: "100%",
      height: "30%",
    },
    // used by the web version
    balanceScrollViewScrollViewWrapper: {
      width: "100%",
      height: "20%",
    },
    // used by the web version
    transactionsListScrollViewScrollViewWrapper: {
      width: "100%",
      height: "38%",
    },
    head: {
      height: "1%",
      width: "100%",
      backgroundColor: theme.colors.background,
    },
    container: {
      flex: 1,
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      flexWrap: "wrap",
      backgroundColor: theme.colors.background,
    },
    separator: {
      // width: "100%",
      height: "5%",
      backgroundColor: theme.colors.background,
    },
    card: {
      // minWidth: 200,
      width: "48%",
      height: "36%",
      margin: "1%",
      overflow: "hidden",
      // backgroundColor: theme.colors.surface,
      // alignItems: "center",
      // justifyContent: "center",
    },
    transactionsCard: {
      // minWidth: 200,
      width: "100%",
      height: "20%",
      margin: "1%",
      // backgroundColor: theme.colors.surface,
      // alignItems: "center",
      // justifyContent: "center",
    },
    scrollView: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      height: "99%",
      // flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    innerScroll: {
      // alignItems: "center",
      // justifyContent: "center",
      // flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      height: "100%",
      flex: 0.1,
      // flexDirection: "row",
      justifyContent: "flex-start",
      // height: 70,
      backgroundColor: theme.colors.background,
    },
  });
  return (
    <View style={styles.container}>
      <View style={styles.head} />
      <Card
        style={styles.transactionsCard}
        onPress={() => {
          navigation.push("accounting.transactions");
        }}
      >
        <Card.Title title="Transactions" subtitle="Liste des transactions" />
        <Card.Content>
          <ErrorBoundary>
            <Text>Cliquer pour voir la liste de transactions</Text>
          </ErrorBoundary>
        </Card.Content>
      </Card>
      <Card
        style={styles.card}
        onPress={() => {
          navigation.navigate("accounting.accounts");
        }}
      >
        <Card.Title title="Comptes" subtitle="Liste des comptes" />
        <Card.Content>
          <ErrorBoundary>
            <InnerAccountsList />
          </ErrorBoundary>
        </Card.Content>
      </Card>
      <Card
        style={styles.card}
        onPress={() => {
          navigation.navigate("accounting.groups");
        }}
      >
        <Card.Title
          title="Groupes"
          subtitle="Edition des groupes et balances des comptes partagés"
        />
        <Card.Content>
          <ErrorBoundary>
            <InnerBalances />
          </ErrorBoundary>
        </Card.Content>
      </Card>
      <Card
        style={styles.card}
        onPress={() => {
          navigation.navigate("accounting.bilan");
        }}
      >
        <Card.Title title="Bilan" subtitle="Bilan comptable" />
        <Card.Content>
          <ErrorBoundary>
            <BilanPreview />
          </ErrorBoundary>
        </Card.Content>
      </Card>
      <Card
        style={styles.card}
        onPress={() => {
          navigation.navigate("accounting.stats", {});
        }}
      >
        <Card.Title title="Stats" subtitle="Voir les stats" />
        <Card.Content>
          <ErrorBoundary>
            <StatsPreview />
          </ErrorBoundary>
        </Card.Content>
      </Card>
    </View>
  );
}
