import { FontAwesome } from "@expo/vector-icons";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useMemo, useState } from "react";
import { Pressable, StyleSheet } from "react-native";
import Picker from "../../../ui/Picker";
import { Text, View } from "../../../Themed";
import { useStatsValuesForSelectQuery } from "../../../../generated/graphql";

function newId() {
  return `${Date.now()}`;
}

export default function StatsComposition({
  selectedStats,
  onChange,
}: {
  selectedStats: { contextId: string; postId: string; id: string }[] | null;
  onChange: (
    selectedStats: { contextId: string; postId: string; id: string }[]
  ) => void;
}) {
  const { data, loading } = useStatsValuesForSelectQuery();
  const [newContextId, setNewContextId] = useState<string>("");
  const [newPostId, setNewPostId] = useState<string>("");
  const styles = StyleSheet.create({
    composition: {
      flexDirection: "column",
      marginTop: 30,
    },
    row: {
      flexDirection: "row",
      margin: 10,
    },
    context: {
      width: "46%",
      marginRight: "2%",
    },
    post: {
      width: "46%",
    },
    delete: {
      paddingLeft: 10,
      width: "6%",
    },
  });
  useEffect(() => {
    if (newContextId && newPostId) {
      const newValue = [
        ...(selectedStats ?? []),
        { contextId: newContextId, postId: newPostId, id: newId() },
      ];
      onChange(newValue);
      setNewContextId("");
      setNewPostId("");
    }
  }, [newContextId, newPostId]);

  const allContexts = useMemo(() => {
    if (!data) {
      return [];
    }
    return uniqBy(
      data.groups.flatMap((group) => {
        return group!.contexts;
      }),
      "id"
    );
  }, [data]);
  const allPosts = useMemo(() => {
    if (!data) {
      return [];
    }
    return uniqBy(
      data.groups.flatMap((group) => {
        return group!.contexts.flatMap((context) => {
          return context.posts;
        });
      }),
      "id"
    );
  }, [data]);
  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <View style={styles.composition}>
      {(selectedStats || []).map((stat) => {
        return (
          <View key={stat.contextId + "-" + stat.postId} style={styles.row}>
            <View style={styles.context}>
              <Picker
                value={stat.contextId}
                onValueChange={(value) => {
                  stat.contextId = value;
                  onChange([...selectedStats!]);
                }}
                items={allContexts.map((context) => {
                  return {
                    label: context.name,
                    value: context.id,
                  };
                })}
              ></Picker>
            </View>
            <View style={styles.post}>
              <Picker
                value={stat.postId}
                onValueChange={(value) => {
                  stat.postId = value;
                  onChange([...selectedStats!]);
                }}
                items={allPosts.map((post) => {
                  return {
                    label: post.name,
                    value: post.id,
                  };
                })}
              ></Picker>
            </View>
            <View style={styles.delete}>
              <Pressable
                onPress={() => {
                  onChange(selectedStats!.filter((s) => s !== stat));
                }}
              >
                <FontAwesome name="remove" size={20} />
              </Pressable>
            </View>
          </View>
        );
      })}
      <View key="new" style={styles.row}>
        <View style={styles.context}>
          <Picker
            testID="newContextID"
            value={newContextId}
            onValueChange={(value) => setNewContextId(value)}
            items={allContexts.map((context) => {
              return {
                label: context.name,
                value: context.id,
              };
            })}
          />
        </View>
        <View style={styles.post}>
          <Picker
            testID="newPostID"
            value={newPostId}
            onValueChange={(value) => setNewPostId(value)}
            items={allPosts.map((post) => {
              return {
                label: post.name,
                value: post.id,
              };
            })}
          />
        </View>
      </View>
    </View>
  );
}
