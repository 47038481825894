import sortBy from "lodash/sortBy";
import React from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View, ViewStyle } from "react-native";
import { Tabs, TabScreen } from "react-native-paper-tabs";
//@ts-expect-error types does not exist
import { RefreshControl } from "react-native-web-refresh-control";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia
} from "rn-placeholder";
import {
  useAccountsQuery,
  useComputeAccountsAmountsMutation
} from "../../../generated/graphql";
import { logger } from "../../../services/logger";
import RenewNeeded from "../RenewNeeded";
import Connections from "./Connections";
import InnerAccountsList from "./Inner";

export default function AccountsList({
  style,
  editable = false,
}: { style: ViewStyle; editable?: boolean }) {
  const [computeAccountsAmountsMutation, { loading: reloading }] =
    useComputeAccountsAmountsMutation();
  const { t, i18n } = useTranslation();
  const { data, loading, error } = useAccountsQuery();
  const renewNeeded =
  //@ts-expect-error not well typed
    error?.networkError?.result?.errors[0].message === "BI_RENEW_NEEDED";
  let accounts = sortBy(data?.currentUser?.accounts ?? [], (a) => {
    return a.name.toLowerCase();
  });
  if (!editable) {
    accounts = accounts.filter((a) => Math.round(a.amount / 100));
  }
  if (loading) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }
  if (renewNeeded) {
    return <RenewNeeded />
  }

  return (
    <ScrollView
      style={{ ...style, minHeight: "100%", flex: 1 }}
      refreshControl={
        <RefreshControl
          refreshing={reloading}
          onRefresh={async () => {
            logger.debug("refresh accounts list");
            computeAccountsAmountsMutation();
          }}
        />
      }
    >
      <View style={{ minHeight: "100%" }}>
        <Tabs style={{ flex: 1, height: "100%" }}>
          <TabScreen label={t("Mes comptes")}>
            <View style={{ flex: 1, height: "100%" }}>
              <InnerAccountsList editable={editable} />
            </View>
          </TabScreen>
          <TabScreen label={t("Connections")}>
            <Connections />
          </TabScreen>
        </Tabs>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  positiveRow: {},
  nagetiveRow: {},
});
