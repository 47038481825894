import React, { useEffect, useState } from "react";
import View from "../../components/ui/View";
import Text from "../../components/ui/Text";
import { useConnectBankMutation } from "../../generated/graphql";
import { useAppNavigation, useAppRoute } from "../../hooks/useAppNavigation";

export default function AccountingConnectScreen() {
  const route = useAppRoute<'accounting.connect'>()
  const navigation = useAppNavigation<'accounting.connect'>()
  const [done, setDone] = useState(false);
  const [connectBankMutation, { loading }] = useConnectBankMutation();
  useEffect(() => {
    if (route.path) {
      if (!loading && !done) {
        setDone(true);
        connectBankMutation({
          variables: {
            budgetInsideClientCode: route.params.code,
            budgetInsideClientID: route.params.connection_id,
          },
        }).then(() => {
          navigation.push("accounting.accounts");
        });
      }
    }
  }, [connectBankMutation, navigation, loading, done]);

  return (
    <View>
      <Text>Connexion du compte en cours ...</Text>
    </View>
  );
}
