import { CurrentUserStocksQuery } from "../../../generated/graphql";
import Surface from "../../ui/Surface";
import Text from "../../ui/Text";
import { StyleSheet } from "react-native";
import { useThemeColor } from "../../Themed";
import { useTheme } from "react-native-paper";
import { differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";

const FEES = 0.02;

type LastEventProps = Pick<
  NonNullable<
    NonNullable<
      CurrentUserStocksQuery["currentUser"]["stocks"][number]["signals"]
    >["rsi"]
  >,
  "lastEvent"
>;

function trend(lastEvent: LastEventProps["lastEvent"]) {
  const theme = useTheme();

  if (!lastEvent) {
    return { backgroundColor: theme.colors.disabled };
  }
  switch (lastEvent?.type) {
    case "SELL":
      if (differenceInDays(new Date(), lastEvent.date) < 3) {
        return { backgroundColor: theme.colors.error, label: "Vendre" };
      }
      return { backgroundColor: "lightorange", label: "Alléger" };
    case "BUY":
      if (differenceInDays(new Date(), lastEvent.date) < 3) {
        return { backgroundColor: "green", label: "Acheter" };
      }
      return { backgroundColor: "lightgreen", label: "Conserver" };
  }
}

export default function LastRSIEvent({ lastEvent }: LastEventProps) {
  const trendValue = trend(lastEvent);
  const { i18n } = useTranslation();

  if (!lastEvent) {
    return (
      <Surface
        style={[
          styles.surface,
          {
            backgroundColor: trendValue.backgroundColor,
          },
        ]}
      >
        <Text>Pas de config de RSI</Text>
      </Surface>
    );
  }
  return (
    <Surface
      style={[
        styles.surface,
        {
          backgroundColor: trendValue.backgroundColor,
        },
      ]}
    >
      <Text>{trendValue.label}</Text>
      <Text>{new Date(lastEvent.date).toLocaleDateString()}</Text>
      {lastEvent.buyValue && lastEvent.sellValue && (
        <Text>
          {Intl.NumberFormat(i18n.language, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(
            100 *
              (-FEES +
                (lastEvent.sellValue - lastEvent.buyValue) /
                  lastEvent.sellValue)
          )}{" "}
          %
        </Text>
      )}
      <Text>
        {Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 2,
          currency: 'EUR',
        }).format(
          lastEvent.sellValue ? lastEvent.sellValue : lastEvent.buyValue
        )}
      </Text>
    </Surface>
  );
}

const styles = StyleSheet.create({
  surface: {
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
    elevation: 4,
    width: 200,
  },
  disabled: {},
});
