import { FontAwesome } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React, { useContext } from "react";
import { Pressable } from "react-native";
import { UserContext } from "../context/UserContext";
import { useAppNavigation } from "../hooks/useAppNavigation";
import Accounts from "../screens/Accounting/AccountingScreen";
import StocksScreen from "../screens/Stocks/StocksScreen";
import UserScreen from "../screens/User/UserScreen";
import { NavigationParamsList } from "./RootNavigator";

const Tab = createBottomTabNavigator<NavigationParamsList>();

function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>["name"];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

export function TabsNavigator() {
  const { user } = useContext(UserContext);
  const navigation = useAppNavigation();

  return (
    <Tab.Navigator screenOptions={{ headerShown: true }}>
      {user ? (
        <>
          <Tab.Screen
            name="accounting"
            component={Accounts}
            options={{
              headerRight: ({ pressColor }) => {
                return (
                  <Pressable onPress={() => {
                    navigation.navigate("accounting.params");
                  }}>
                    <TabBarIcon name="cogs" color={pressColor ?? "black"} />
                  </Pressable>
                );
              },
              title: "Comptes",
              tabBarIcon: ({ color }) => (
                <TabBarIcon name="list" color={color} />
              ),
            }}
          />
          <Tab.Screen
            name="stocks"
            component={StocksScreen}
            options={{
              title: "Actions",
              tabBarIcon: ({ color }) => (
                <TabBarIcon name="line-chart" color={color} />
              ),
            }}
          />
        </>
      ) : null}

      <Tab.Screen
        name="profile"
        component={UserScreen}
        options={{
          title: "Profil",
          tabBarIcon: ({ color }) => <TabBarIcon name="user" color={color} />,
        }}
      />
    </Tab.Navigator>
  );
}
