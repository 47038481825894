import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ScrollView,
  StyleSheet,
  FlatList,
  RefreshControl,
  Pressable,
} from "react-native";
import { IconButton } from "react-native-paper";
import {
  useAllStocksQuery,
  useCurrentUserStocksQuery,
  useFollowStockMutation,
  useRefetchStockDataMutation,
  useTestSignalsForStockMutation,
  useUnfollowStockMutation,
} from "../../../generated/graphql";
import Amount from "../../Amount";
import Picker from "../../ui/Picker";
import { View, Text } from "../../Themed";
import { FontAwesome } from "@expo/vector-icons";
import LastEvent from "./LastRSIEvent";
import { useAppNavigation } from "../../../hooks/useAppNavigation";

export default function StocksList() {
  const {
    data: currentUserStocksData,
    loading: loadingCurrentUserStocks,
    refetch: refetchCurrentUserStocks,
  } = useCurrentUserStocksQuery();
  const { data: allStocksData, loading: allStocksLoading } =
    useAllStocksQuery();
  const [followStock, { loading: followStockLoading }] =
    useFollowStockMutation();
  const [unfollowStock, { loading: unfollowStockLoading }] =
    useUnfollowStockMutation();

  const [selectedNewStock, setSelectedNewStock] = useState<string>("");
  const { i18n } = useTranslation();
  const [refetchStockData, { loading: refetchStockDataLoading }] =
    useRefetchStockDataMutation();
  const [testSignalsForStock, { loading: testSignalsForStockLoading }] =
    useTestSignalsForStockMutation();
  const navigation = useAppNavigation();

  const items = useMemo(() => {
    return (allStocksData?.stocks ?? [])
      .filter((stock) => !stock.currentUser?.follow)
      .map((stock) => {
        return {
          value: stock.id,
          label: stock.name,
        };
      });
  }, [allStocksData, currentUserStocksData]);
  if (followStockLoading || loadingCurrentUserStocks || allStocksLoading) {
    return <Text>Loading...</Text>;
  }
  const styles = StyleSheet.create({
    addLine: {
      width: "100%",
      flex: 1,
      margin: 10,
      flexDirection: "row",
    },
    select: {
      width: "50%",
      height: 30,
    },
    separator: {
      flex: 1,
      width: "100%",
      paddingTop: 10,
    },
    stockRow: {
      flex: 1,
      width: "100%",
      padding: 10,
      height: 400,
      flexDirection: "row",
    },
    stockName: {
      fontWeight: "bold",
      margin: 5,
      width: 300,
    },
    stockDate: {
      margin: 5,
      width: 100,
    },
  });

  return (
    <ScrollView>
      <View style={styles.addLine}>
        <View style={styles.select}>
          <Picker
            onValueChange={(stockId) => {
              setSelectedNewStock(stockId);
            }}
            value={selectedNewStock}
            items={items}
          />
        </View>
        <View style={styles.select}>
          <IconButton
            icon="plus"
            onPress={() => {
              followStock({
                variables: {
                  stockId: selectedNewStock,
                },
              });
            }}
          ></IconButton>
        </View>
      </View>
      <FlatList
        ItemSeparatorComponent={() => {
          return <View style={styles.separator}></View>;
        }}
        refreshControl={
          <RefreshControl
            refreshing={loadingCurrentUserStocks}
            onRefresh={() => {
              refetchCurrentUserStocks();
            }}
          />
        }
        data={currentUserStocksData?.currentUser.stocks}
        renderItem={(item) => {
          const last = item.item.stock.historical2?.edges[0]?.node;
          const dayBefore = item.item.stock.historical2?.edges[1]?.node;
          let trendIcon = <FontAwesome name="arrow-right" />;
          let color = undefined;
          if (last?.close && dayBefore?.close) {
            if (last.close > dayBefore.close) {
              color = "green";
              trendIcon = (
                <FontAwesome style={{ margin: 5, color }} name="arrow-up" />
              );
            }
            if (last.close < dayBefore.close) {
              color = "red";
              trendIcon = (
                <FontAwesome style={{ margin: 5, color }} name="arrow-down" />
              );
            }
          }
          return (
            <Pressable
              onPress={() => {
                navigation.navigate("stocks.detail", {
                  stockId: item.item.stock.id,
                });
              }}
            >
              <View style={styles.stockRow}>
                <Text style={styles.stockName}>{item.item.stock.name}</Text>
                <Text style={styles.stockDate}>
                  {new Date(last?.date).toLocaleDateString(i18n.language)}
                </Text>
                {last?.close && dayBefore?.close ? (
                  <>
                    {trendIcon}
                    <Text style={{ color, margin: 5 }}>
                      {(
                        (100 * (last?.close - dayBefore?.close)) /
                        dayBefore?.close
                      ).toFixed(2)}
                      %
                    </Text>
                    <Amount style={{ color, margin: 5, width: 100 }}>
                      {last?.close * 100}
                    </Amount>
                  </>
                ) : null}
              </View>
              <View style={styles.stockRow}>
                <IconButton
                  icon="minus"
                  disabled={refetchStockDataLoading}
                  onPress={() => {
                    unfollowStock({
                      variables: {
                        stockId: item.item.stock.id,
                      },
                    }).then(() => {
                      refetchCurrentUserStocks();
                    });
                  }}
                />
                <IconButton
                  icon="refresh"
                  disabled={refetchStockDataLoading}
                  onPress={() => {
                    refetchStockData({
                      variables: {
                        id: item.item.stock.id,
                      },
                    }).then(() => {
                      testSignalsForStock({
                        variables: {
                          stockId: item.item.stock.id,
                        },
                      });
                    });
                  }}
                />
                <LastEvent lastEvent={item.item.signals?.rsi?.lastEvent} />
              </View>
            </Pressable>
          );
        }}
        keyExtractor={(item) => {
          return item.id;
        }}
      ></FlatList>
    </ScrollView>
  );
}
