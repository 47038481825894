import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ScrollView, StyleSheet, View } from "react-native";
import { Title } from "react-native-paper";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import {
  useDeleteAutomaticOperationMutation,
  useEditAutomaticOperationMutation,
  useCreateAutomaticOperationMutation,
  useAutomaticOperationsQuery,
} from "../../generated/graphql";
import DateInputForm from "../form/DateInputForm";
import H2 from "../ui/H2";
import AutomaticOperation from "./AutomaticOperation";

export default function AccountingAutomaticOperations() {
  const { data: automaticOperationsData, loading: automaticOperationsLoading } =
    useAutomaticOperationsQuery();
  const [deleteAutomaticOperation] = useDeleteAutomaticOperationMutation();
  const [editAutomaticOperation] = useEditAutomaticOperationMutation();
  const [createAutomaticOperation] = useCreateAutomaticOperationMutation();

  if (!automaticOperationsData || automaticOperationsLoading) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  return (
    <ScrollView>
      {automaticOperationsData.groups.map((group) => {
        return (
          <View key={group!.id} style={styles.group}>
            <H2>{group.name}</H2>
            {group.automaticOperations.map((automaticOperation) => {
              return (
                <AutomaticOperation key={automaticOperation.id} automaticOperation={automaticOperation} />
              );
            })}
          </View>
        );
      })}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  group: {},
  automaticOperation: {
    flex: 1,
    flexDirection: "row",
  },
  inputWrapper: {},
});
