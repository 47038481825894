import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FlatList, RefreshControl, StyleSheet, TextInput } from "react-native";
import { IconButton, useTheme } from "react-native-paper";
import NumberInputForm from "../../form/NumberInputForm";
import View from "../../ui/View";
import { RSISignals, years } from "@jtassin/money-services";
import {
  StockDetailQuery,
  useUpdateRsiSignalMutation,
  useTestSignalsForStockMutation,
} from "../../../generated/graphql";
import Text from "../../ui/Text";
import { Event } from "./Event";
import { getVariableValues } from "graphql";
interface RSIConfigProps {
  stockId: NonNullable<StockDetailQuery["stock"]["id"]>;
  rsiConfig: NonNullable<
    StockDetailQuery["stock"]["currentUser"]["signals"]
  >["rsi"];
  historical: NonNullable<StockDetailQuery["stock"]["historical2"]>;
}

export default function RSIConfig({
  stockId,
  rsiConfig,
  historical,
}: RSIConfigProps) {
  const methods = useForm({
    defaultValues: {
      buySignal: (rsiConfig && rsiConfig?.buySignal) ?? 0,
      sellSignal: (rsiConfig && rsiConfig?.sellSignal) ?? 0,
      maxBuys: (rsiConfig && rsiConfig?.maxBuys) ?? 0,
      stopLoss: (rsiConfig && rsiConfig?.stopLoss) ?? 0,
    },
  });
  const [events, setEvents] = useState<
    ReturnType<typeof RSISignals.computeGains>["events"]
  >([]);
  const [updateRsiSignalMutation, { loading }] = useUpdateRsiSignalMutation();
  const [testSignalsForStockMutation, { loading: signalLoading }] =
    useTestSignalsForStockMutation();
  const [computing, setComputing] = useState(false);
  const [gain, setGain] = useState<number | null>(null);
  const buySignalRef: React.Ref<any> = useRef();
  const theme = useTheme();
  const sellSignalRef: React.Ref<any> = useRef();
  const maxBuysRef: React.Ref<any> = useRef();
  const stopLossRef: React.Ref<any> = useRef();
  const historicalData = useMemo(() => {
    return historical.edges
      .filter((edge) => edge)
      .map((edge) => {
        return {
          date: edge.node.date,
          adjClose: edge.node.adjClose ?? undefined,
          low: edge.node.low ?? undefined,
          rsi14: edge.node.rsi14 ?? undefined,
        };
      })
      .reverse();
  }, [historical]);
  useEffect(() => {
    if (
      rsiConfig?.buySignal &&
      rsiConfig?.sellSignal &&
      rsiConfig?.maxBuys &&
      rsiConfig?.stopLoss
    ) {
      const computedYears = Math.abs(years(historicalData));
      const newGains = RSISignals.computeGains({
        buySignal: rsiConfig.buySignal,
        sellSignal: rsiConfig.sellSignal,
        data: historicalData,
        maxBuys: rsiConfig.maxBuys,
        stopLoss: rsiConfig.stopLoss,
        years: computedYears,
        fees: 0.01,
      });
      setGain(newGains.gainPerYear ?? 0);
      setEvents(newGains.events);
    }
  }, []);
  return (
    <View>
      <FormProvider {...methods}>
        <View>
          <NumberInputForm
            label="Signal d'achat"
            name="buySignal"
            ref={buySignalRef}
          ></NumberInputForm>
        </View>
        <View>
          <NumberInputForm
            label="Signal de vente"
            name="sellSignal"
            ref={sellSignalRef}
          ></NumberInputForm>
        </View>
        <View>
          <NumberInputForm
            label="Nombre de lignes"
            name="maxBuys"
            ref={maxBuysRef}
          ></NumberInputForm>
        </View>
        <View>
          <NumberInputForm
            label="Stop loss"
            name="stopLoss"
            ref={stopLossRef}
          ></NumberInputForm>
        </View>
        <View>
          <Text>Gains : {((gain ?? 0) * 100).toFixed(2)}% par année</Text>
        </View>
        <View style={styles.row}>
          <View style={styles.laptop}>
            <IconButton
              icon="laptop"
              disabled={computing || loading || signalLoading}
              onPress={async () => {
                setComputing(true);
                const computed = await RSISignals.computeBestRsiTreeshold({
                  data: historicalData,
                  progressAsyncCallback: console.log,
                });
                console.log("computed", computed);
                methods.setValue("buySignal", computed.buySignal);
                methods.setValue("sellSignal", computed.sellSignal);
                methods.setValue("stopLoss", computed.stopLoss);
                methods.setValue("maxBuys", computed.maxBuys);
                const gainPerYear =
                  computed.gains / Math.abs(years(historicalData));
                setGain(gainPerYear);
                setComputing(false);
              }}
            />
          </View>
          <View style={styles.laptop}>
            <IconButton
              icon="plus"
              disabled={computing || loading || signalLoading}
              onPress={async () => {
                setComputing(true);
                const newGains = RSISignals.computeGains({
                  buySignal: methods.getValues('buySignal'),
                  sellSignal: methods.getValues('sellSignal'),
                  data: historicalData,
                  maxBuys: methods.getValues('maxBuys'),
                  stopLoss: methods.getValues('stopLoss'),
                  years: Math.abs(years(historicalData)),
                  fees: 0.01,
                });
                setGain(newGains.gainPerYear ?? 0);
                setComputing(false);
              }}
            />
          </View>
          <View style={styles.floppy}>
            <IconButton
              disabled={computing || loading || signalLoading}
              icon="floppy"
              onPress={async () => {
                await updateRsiSignalMutation({
                  variables: {
                    stockId,
                    input: methods.getValues(),
                  },
                });
                testSignalsForStockMutation({
                  variables: {
                    stockId,
                  },
                });
              }}
            />
          </View>
        </View>
      </FormProvider>
      <View>
        <FlatList
          ItemSeparatorComponent={() => {
            return (
              <View
                style={{
                  height: 1,
                  width: "100%",
                  backgroundColor: theme.colors.background,
                }}
              ></View>
            );
          }}
          refreshControl={
            <RefreshControl
              refreshing={signalLoading}
              onRefresh={() => {
                testSignalsForStockMutation({
                  variables: {
                    stockId,
                  },
                });
              }}
            />
          }
          data={events}
          renderItem={(event) => {
            return <Event event={event} />;
          }}
        ></FlatList>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "space-between",
  },
  laptop: {},
  plus: {},
  floppy: {},
});
