import React from "react";
import { useFormContext } from "react-hook-form";
import { TextInput as NativeTextInput } from "react-native";
import { TextInput } from "react-native-paper";

interface TextInputFormProps {
  name: string;
  label?: string;
}

const TextInputForm = React.forwardRef<NativeTextInput, TextInputFormProps>(
  ({ name, label }, ref) => {
    const {
      register,
      setValue,
      getValues,
      formState: { errors },
    } = useFormContext();
    const { onChange, ...form } = register(name, {})
    const value = getValues()[name];
    return (
      <TextInput
        {...form}
        value={value}
        label={label}
        ref={ref}
        onChangeText={(text) => {
          setValue(name, text);
        }}
        error={Boolean(errors[name])}
      />
    );
  }
);

export default TextInputForm;
