import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Pressable } from "react-native";
import { DatePickerModal } from "react-native-paper-dates";
import View from "../ui/View";
import Text from "../ui/Text";

export default function DateInputForm({ name }: { name: string }) {
  const { setValue, getValues } = useFormContext();
  const { i18n } = useTranslation();
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const value = getValues()[name];
  const hideDatePicker = () => {
    setDatePickerVisible(false);
  };
  const showDatePicker = () => {
    setDatePickerVisible(true);
  };
  if (!isDatePickerVisible) {
    return (
      <Pressable onPress={showDatePicker}>
        <View>
          <Text>
            {new Date(getValues("date")).toLocaleString(i18n.language, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </Text>
        </View>
      </Pressable>
    );
  }
  return (
    <DatePickerModal
      visible={isDatePickerVisible}
      locale={i18n.language}
      mode="single"
      onDismiss={hideDatePicker}
      date={value}
      onConfirm={({ date }) => {
        setValue(name, date);
        hideDatePicker();
      }}
    />
  );
}
