import React from "react";
import { Text as DefaultText, TextProps } from "react-native";
import { useThemeColor } from "../Themed";
import { StyleSheet } from "react-native";

export default function H1(props: TextProps) {
  const { style, ...otherProps } = props;
  const color = useThemeColor({}, "text");

  return (
    <DefaultText style={[styles.text, { color }, style]} {...otherProps} />
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 20,
  },
});
