import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { Pressable, ScrollView, StyleSheet } from "react-native";
import { IconButton } from "react-native-paper";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import { useStockDetailQuery } from "../../../generated/graphql";
import { useAppRoute } from "../../../hooks/useAppNavigation";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTheme,
} from "../../../services/victory";
import H1 from "../../ui/H1";
import View from "../../ui/View";
import LastRSIEvent from "../StocksList/LastRSIEvent";
import RSIConfig from "./RSIConfig";

export default function StockDetail() {
  const [tab, setTab] = useState<"rsi" | "sma" | null>(null);
  const route = useAppRoute<"stocks.detail">();
  const { data: stockDetailData, loading: stockDetailLoading } =
    useStockDetailQuery({
      variables: {
        id: route.params.stockId,
      },
    });
  const [lineChartData, min, max] = useMemo(() => {
    if (!stockDetailData) {
      return [];
    }
    const data =
      stockDetailData.stock.historical2?.edges
        .map((edge) => {
          return {
            y: edge.node.adjClose,
            x: format(new Date(edge.node.date), "yyyy-MM-dd"),
          };
        })
        .slice(0, 100)
        .reverse() ?? [];
    const max = Math.max(...data.map((d) => d.y ?? 0));
    const min = Math.min(...data.map((d) => d.y ?? 0));
    return [data, max, min];
  }, [stockDetailData]);
  if (stockDetailLoading || !stockDetailData) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }
  return (
    <ScrollView>
      <H1>{stockDetailData.stock.name}</H1>
      <VictoryChart theme={VictoryTheme.material}>
        <VictoryAxis dependentAxis />
        <VictoryAxis
          gridComponent={<></>}
          tickCount={5}
          tickLabelComponent={<VictoryLabel angle={-45} textAnchor="end" />}
        />
        <VictoryLine
          style={{
            data: { stroke: "#c43a31" },
          }}
          labels={({ datum }) => {
            if (datum.y === min || datum.y === max) {
              return datum.y.toFixed(2);
            }
            return undefined;
          }}
          data={lineChartData}
        />
      </VictoryChart>
      {tab === null && (
        <View style={[styles.signalsAndConfigRow, styles.signalsRow]}>
          <Pressable
            onPress={() => {
              setTab("rsi");
            }}
          >
            <LastRSIEvent
              lastEvent={
                stockDetailData.stock.currentUser.signals?.rsi?.lastEvent
              }
            />
          </Pressable>
        </View>
      )}
      <View style={[styles.signalsAndConfigRow, styles.configRow]}>
        {tab === "rsi" && (
          <View>
            <View>
              <IconButton
                icon="close"
                onPress={() => {
                  setTab(null);
                }}
              />
            </View>
            <RSIConfig
              stockId={route.params.stockId}
              historical={stockDetailData.stock.historical2!}
              rsiConfig={stockDetailData.stock.currentUser.signals?.rsi}
            />
          </View>
        )}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  signalsAndConfigRow: {
    marginTop: 100,
  },
  signalsRow: {
    flex: 1,
    flexDirection: "row",
  },
  configRow: {},
});
