import { startOfDay } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet } from "react-native";
import { DataTable, useTheme } from "react-native-paper";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import { BilanQuery, useBilanQuery } from "../../../generated/graphql";
import Amount from "../../Amount";
import { Text, View } from "../../Themed";
import TranslatedText from "../../TranslatedText";

function PanelRows({
  bilanItems,
}: {
  bilanItems: BilanQuery["bilan"]["active"] | BilanQuery["bilan"]["passive"];
}) {
  const theme = useTheme();
  return (
    <>
      {bilanItems.map((activeRow) => {
        return (
          <React.Fragment key={activeRow.name}>
            <DataTable.Header>
              <DataTable.Title>
                <Text style={{color: theme.colors.placeholder}}>{activeRow.name}</Text>
              </DataTable.Title>
            </DataTable.Header>
            {activeRow.items.map((row) => {
              return (
                <DataTable.Row key={row.id}>
                  <DataTable.Cell>
                    <Text>{row.name ?? row.account.name}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell numeric>
                    <Text>
                      <Amount maximumFractionDigits={0}>{row.amount}</Amount>
                    </Text>
                  </DataTable.Cell>
                </DataTable.Row>
              );
            })}
            <DataTable.Header>
              <DataTable.Title>
                <TranslatedText style={{fontWeight: 'bold'}}>Total</TranslatedText>
              </DataTable.Title>
              <DataTable.Title numeric>
                <Amount maximumFractionDigits={0} style={{fontWeight: 'bold'}}>
                  {activeRow.items.reduce((acc, row) => acc + row.amount, 0)}
                </Amount>
              </DataTable.Title>
            </DataTable.Header>
          </React.Fragment>
        );
      })}
    </>
  );
}

export default function Bilan() {
  const { loading, data, refetch } = useBilanQuery({
    variables: {
      date: startOfDay(new Date()).toISOString(),
    },
  });
  const { t } = useTranslation();
  const theme = useTheme();

  if (loading || !data) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  const styles = StyleSheet.create({
    container: {
      width: "100%",
      justifyContent: "center",
      alignContent: "center",
    },
    rows: {
      flexDirection: "row",
      backgroundColor: theme.colors.surface,
    },
    scrollView: {
      width: "100%",
    },
    panel: {
      width: "50%",
    },
  });

  return (
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>
        <View style={styles.rows}>
          <DataTable style={styles.panel}>
            <DataTable.Header>
              <DataTable.Title>
                <TranslatedText style={{color: theme.colors.accent}}>Actif</TranslatedText>
              </DataTable.Title>
            </DataTable.Header>
            <PanelRows bilanItems={data.bilan.active} />
          </DataTable>
          <DataTable style={styles.panel}>
            <DataTable.Header>
              <DataTable.Title>
                <TranslatedText style={{color: theme.colors.notification}}>Passif</TranslatedText>
              </DataTable.Title>
              <DataTable.Title>
                <Text></Text>
              </DataTable.Title>
            </DataTable.Header>
            <PanelRows bilanItems={data.bilan.passive} />
          </DataTable>
        </View>
        <View
          style={{
            height: 10,
            width: "100%",
            backgroundColor: theme.colors.surface,
          }}
        ></View>
        <View style={styles.rows}>
          <DataTable style={styles.panel}>
          <DataTable.Header style={{backgroundColor: theme.colors.placeholder}}>
              <DataTable.Title>
                <TranslatedText>Total Actif</TranslatedText>
              </DataTable.Title>
              <DataTable.Title numeric>
                <Amount maximumFractionDigits={0}>
                  {data.bilan.totalActive}
                </Amount>
              </DataTable.Title>
            </DataTable.Header>
            <DataTable.Header>
              <DataTable.Title>
                <TranslatedText style={{color: theme.colors.accent}}>Résultat</TranslatedText>
              </DataTable.Title>
              <DataTable.Title numeric>
                  <Amount style={{color: theme.colors.accent}} maximumFractionDigits={0}>{data.bilan.total}</Amount>
              </DataTable.Title>
            </DataTable.Header>
          </DataTable>
          <DataTable style={styles.panel}>
            <DataTable.Header style={{backgroundColor: theme.colors.placeholder}}>
              <DataTable.Title>
                <TranslatedText>Total Passif</TranslatedText>
              </DataTable.Title>
              <DataTable.Title numeric>
                <Text>
                  <Amount maximumFractionDigits={0}>
                    {data.bilan.totalPassive}
                  </Amount>
                </Text>
              </DataTable.Title>
            </DataTable.Header>
          </DataTable>
        </View>
      </View>
    </ScrollView>
  );
}
