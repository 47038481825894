import React, { useMemo } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyleSheet, TextInput } from "react-native";
import { IconButton } from "react-native-paper";
import Picker from "../../components/ui/Picker";
import { View } from "../../components/Themed";
import TranslatedText from "../../components/TranslatedText";
import { useTransactionsListValuesForSelectQuery } from "../../generated/graphql";
import { useAppNavigation, useAppRoute } from "../../hooks/useAppNavigation";
import { uniqBy } from "lodash";

export default function TransactionsSearchScreen() {
  const { data: transactionsListValuesForSelectData } =
    useTransactionsListValuesForSelectQuery();
  const { t } = useTranslation();
  const route = useAppRoute<"accounting.search">();
  const comment = route.params?.comment;
  const postIds = route.params?.postIds;
  const contextIds = route.params?.contextIds;
  const userId = route.params?.userIds;
  const groupId = route.params?.groupIds;
  const accountIds = route.params?.accountIds;

  const search = {
    ...(comment ? { comment } : {}),
    ...(contextIds ? { contextId: contextIds } : {}),
    ...(postIds ? { postId: postIds } : {}),
    ...(groupId ? { groupId } : {}),
    ...(userId ? { userId } : {}),
    ...(accountIds ? { accountId: accountIds } : {}),
  };
  const { control, getValues, setValue } = useForm({
    defaultValues: {
      comment: search.comment,
      postId: search.postId,
      contextId: search.contextId,
      groupId: search.groupId,
      userId: search.userId,
      accountId: search.accountId,
    },
  });
  const navigation = useAppNavigation();
  const amountController = useController({
    control,
    name: "comment",
  });
  const allContexts = useMemo(() => {
    if (!transactionsListValuesForSelectData) {
      return [];
    }
    return uniqBy(
      transactionsListValuesForSelectData.groups.flatMap((group) => {
        return group!.contexts;
      }),
      "id"
    );
  }, [transactionsListValuesForSelectData]);
  console.log(allContexts);
  const allPosts = useMemo(() => {
    if (!transactionsListValuesForSelectData) {
      return [];
    }
    return uniqBy(
      transactionsListValuesForSelectData.groups.flatMap((group) => {
        return group!.contexts.flatMap((context) => {
          return context.posts;
        });
      }),
      "id"
    );
  }, [transactionsListValuesForSelectData]);
  return (
    <form>
      <View style={styles.container}>
        <TranslatedText>Commentaire</TranslatedText>
        <TextInput
          placeholder={t("Commentaire")}
          style={{ backgroundColor: "white" }}
          keyboardType="decimal-pad"
          testID="amount"
          value={amountController.field.value}
          onChangeText={(value) => {
            amountController.field.onChange(
              value.replace(",", ".").replace(/a-zA-Z/, "")
            );
          }}
        />
        <TranslatedText>Foyer</TranslatedText>
        <Controller
          name="groupId"
          control={control}
          render={({ field }) => {
            return (
              <Picker
                value={field.value}
                onValueChange={(itemValue) => {
                  field.onChange(itemValue);
                }}
                items={
                  transactionsListValuesForSelectData?.groups.map((group) => {
                    return {
                      label: `${group!.name}`,
                      value: group!.id,
                    };
                  }) ?? []
                }
              ></Picker>
            );
          }}
        />
        <TranslatedText>Personne</TranslatedText>
        <Controller
          name="userId"
          control={control}
          render={({ field }) => {
            return (
              <Picker
                value={field.value}
                onValueChange={(itemValue) => {
                  field.onChange(itemValue);
                }}
                items={
                  transactionsListValuesForSelectData?.users.map((user) => {
                    return {
                      label: `${user!.firstname}`,
                      value: user!.id,
                    };
                  }) ?? []
                }
              ></Picker>
            );
          }}
        />
        <TranslatedText>Compte</TranslatedText>
        <Controller
          name="accountId"
          control={control}
          render={({ field }) => {
            return (
              <Picker
                value={field.value}
                onValueChange={(itemValue) => {
                  field.onChange(itemValue);
                }}
                items={
                  transactionsListValuesForSelectData?.users.flatMap((user) => {
                    if (!user) {
                      return [];
                    }
                    return user.accounts.map((account) => {
                      return {
                        label: `${user.firstname} ${account.name}`,
                        value: account.id,
                      };
                    });
                  }) ?? []
                }
              ></Picker>
            );
          }}
        />
        <TranslatedText>Catégorie</TranslatedText>
        <Controller
          name="contextId"
          control={control}
          render={({ field }) => {
            return (
              <Picker
                value={field.value}
                onValueChange={(itemValue) => {
                  field.onChange(itemValue);
                }}
                items={
                  allContexts.map((context) => {
                    return {
                      label: `${context.name}`,
                      value: context.id,
                    };
                  }) ?? []
                }
              ></Picker>
            );
          }}
        />
        <TranslatedText>Poste</TranslatedText>
        <Controller
          name="postId"
          control={control}
          render={({ field }) => {
            return (
              <Picker
                value={field.value}
                onValueChange={(itemValue) => {
                  field.onChange(itemValue);
                }}
                items={
                  allPosts.map((post) => {
                    return {
                      label: `${post.name}`,
                      value: post.id,
                    };
                  }) ?? []
                }
              ></Picker>
            );
          }}
        />
      </View>
      <IconButton
        onPress={() => {
          navigation.navigate("accounting.transactions", {
            comment: getValues().comment,
            accountIds: getValues().accountId
              ? getValues().accountId
              : undefined,
            userIds: getValues().userId ? getValues().userId : undefined,
            groupIds: getValues().groupId ? getValues().groupId : undefined,
            contextIds: getValues().contextId ? getValues().contextId : undefined,
            postIds: getValues().postId ? getValues().postId : undefined,
          });
        }}
        icon="search"
      ></IconButton>
    </form>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
