import React, { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ScrollView, StyleSheet, View } from "react-native";
import { Title } from "react-native-paper";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import {
  useDeleteAutomaticOperationMutation,
  useEditAutomaticOperationMutation,
  useCreateAutomaticOperationMutation,
  useAutomaticOperationsQuery,
  AutomaticOperationsQuery,
} from "../../generated/graphql";
import DateInputForm from "../form/DateInputForm";
import TextInputForm from "../form/TextInputForm";
import H2 from "../ui/H2";

export default function AutomaticOperation({
  automaticOperation,
}: {
  automaticOperation: AutomaticOperationsQuery["groups"][number]["automaticOperations"][number];
}) {
  const { data: automaticOperationsData, loading: automaticOperationsLoading } =
    useAutomaticOperationsQuery();
  const [deleteAutomaticOperation] = useDeleteAutomaticOperationMutation();
  const [editAutomaticOperation] = useEditAutomaticOperationMutation();
  const [createAutomaticOperation] = useCreateAutomaticOperationMutation();

  const methods = useForm({
    defaultValues: {
      date: new Date(automaticOperation.nextDate),
      comment: automaticOperation.comment,
    },
  });
  const commentInputRef = useRef();
  return (
    <View style={styles.automaticOperation}>
      <FormProvider {...methods}>
        <View style={styles.inputWrapper}>
          <DateInputForm name="date" />
          <TextInputForm name="comment" autoComplete={false} ref={commentInputRef} />
        </View>
      </FormProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  group: {},
  automaticOperation: {
    flex: 1,
    flexDirection: "row",
  },
  inputWrapper: {},
});
