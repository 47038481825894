import React from "react";
import { ScrollView } from "react-native";
import StockDetail from "../../components/Stocks/StockDetail";
import View from "../../components/ui/View";

export default function StockDetailScreen() {
  return (
    <ScrollView>
      <View>
        <StockDetail />
      </View>
    </ScrollView>
  );
}
