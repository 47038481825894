import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Text, TextInput, View } from "react-native";
import { Checkbox, DataTable, useTheme } from "react-native-paper";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia
} from "rn-placeholder";
import {
  AccountType,
  useAccountsQuery,
  useAddAccountToUserMutation,
  useUpdateUserAccountMutation
} from "../../../../generated/graphql";
import { useAppNavigation } from "../../../../hooks/useAppNavigation";
import TranslatedText from "../../../TranslatedText";
import Picker from "../../../ui/Picker";

function TypePicker({
  disabled,
  onValueChange,
  value,
}: {
  disabled?: boolean;
  onValueChange: (value: string) => void;
  value: string;
}) {
  const { t, i18n } = useTranslation();

  return (
    <Picker
      disabled={disabled}
      onValueChange={onValueChange}
      value={value}
      items={[
        {
          value: AccountType.Disponibilite,
          label: t("Disponibilite"),
        },
        {
          value: AccountType.Emprunt,
          label: t("Emprunt"),
        },
        {
          value: AccountType.EpargneBloquee,
          label: t("Epargne Bloquee"),
        },
        {
          value: AccountType.EpargneNonBloque,
          label: t("Epargne Non Bloqué"),
        },
        {
          value: AccountType.Immobilisation,
          label: t("Immobilisation"),
        },
        {
          value: AccountType.Provision,
          label: t("Provision"),
        },
      ]}
    />
  );
}

export default function InnerAccountsList({
  editable = false,
}: {
  editable?: boolean;
}) {
  const navigation = useAppNavigation();
  const [bankAccounts, setBankAccounts] = useState<
    Record<string, string | null>
  >({});
  const theme = useTheme();
  const [updateUserAccountMutation, { loading: updateUserAccountLoading }] =
    useUpdateUserAccountMutation();
  const [addAccountToUser, { loading: addAccountToUserLoading }] =
    useAddAccountToUserMutation();
  const [newName, setNewName] = useState("");
  const [newType, setNewType] = useState<AccountType>(
    AccountType.Disponibilite
  );
  const processing = updateUserAccountLoading || addAccountToUserLoading;
  const { t, i18n } = useTranslation();
  const { data, loading } = useAccountsQuery();
  let accounts = sortBy(data?.currentUser?.accounts ?? [], (a) => {
    return a.name.toLowerCase();
  });
  if (!editable) {
    accounts = accounts.filter((a) => Math.round(a.amount / 100));
  }
  if (loading) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  return (
    <View>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>
            <Text>Compte</Text>
          </DataTable.Title>
          {editable ? (
            <>
              <DataTable.Title>
                <TranslatedText>Enabled</TranslatedText>
              </DataTable.Title>
              <DataTable.Title>
                <TranslatedText>Type</TranslatedText>
              </DataTable.Title>
              <DataTable.Title>
                <TranslatedText>Associated to</TranslatedText>
              </DataTable.Title>
            </>
          ) : undefined}
          <DataTable.Title numeric>
            {" "}
            <Text>{t("amount")}</Text>
          </DataTable.Title>
        </DataTable.Header>
        {accounts.map((account) => {
          let rowBackground = theme.colors.surface;
          if (account.enabled) {
            if (account.amount < 0) {
              rowBackground = theme.colors.notification;
            }
          } else {
            rowBackground = theme.colors.disabled;
          }
          return (
            <DataTable.Row
              key={account.id}
              style={{ backgroundColor: rowBackground }}
            >
              <DataTable.Cell>
                <Text>{account.name} </Text>
              </DataTable.Cell>
              {editable ? (
                <>
                  <DataTable.Cell>
                    <Checkbox
                      disabled={processing}
                      status={account.enabled ? "checked" : "unchecked"}
                    />
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <TypePicker
                      disabled={processing}
                      onValueChange={(value) => {
                        if (value !== account.type) {
                          updateUserAccountMutation({
                            variables: {
                              userId: data?.currentUser.id!,
                              account: {
                                enabled: account.enabled ?? true,
                                id: account.id,
                                name: account.name,
                                type: value as AccountType,
                                bankAccountId: account.bankAccount?.id,
                              },
                            },
                          });
                        }
                      }}
                      value={account.type}
                    />
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <Picker
                      disabled={processing}
                      onValueChange={(value) => {
                        if (!processing && value !== account.bankAccount?.id) {
                          setBankAccounts({
                            ...bankAccounts,
                            [account.id]: value,
                          });
                          updateUserAccountMutation({
                            variables: {
                              userId: data?.currentUser.id!,
                              account: {
                                enabled: account.enabled ?? true,
                                id: account.id,
                                name: account.name,
                                type: account.type,
                                bankAccountId: value,
                              },
                            },
                          });
                        }
                      }}
                      value={
                        bankAccounts[account.id] ??
                        account.bankAccount?.id ??
                        ""
                      }
                      items={
                        data?.banksOfUser.flatMap((bank) => {
                          return bank.accounts.map((account) => {
                            return {
                              label: account.name,
                              value: account.id,
                            };
                          });
                        }) || []
                      }
                    />
                  </DataTable.Cell>
                </>
              ) : undefined}
              <DataTable.Cell numeric>
                <Text>
                  {new Intl.NumberFormat(i18n.language, {
                    minimumFractionDigits: 2,
                  }).format(Math.round(account.amount / 100))}{" "}
                  €
                </Text>
              </DataTable.Cell>
            </DataTable.Row>
          );
        })}
        <DataTable.Row>
          <DataTable.Cell>
            <TextInput
              editable={!processing}
              onChangeText={(value) => {
                setNewName(value);
              }}
              style={{ backgroundColor: "white" }}
            ></TextInput>
          </DataTable.Cell>
          <DataTable.Cell>
            <Text></Text>
          </DataTable.Cell>
          <DataTable.Cell>
            <Text></Text>
          </DataTable.Cell>
          <DataTable.Cell>
            <TypePicker
              value={newType}
              onValueChange={(value) => setNewType(value as AccountType)}
            />
          </DataTable.Cell>
          <DataTable.Cell numeric>
            <Button
              disabled={processing}
              onPress={() => {
                addAccountToUser({
                  variables: {
                    enabled: true,
                    name: newName,
                    type: newType,
                    userId: data?.currentUser.id!,
                    bankAccountId: "",
                  },
                });
              }}
              title={t("Save")}
            ></Button>
          </DataTable.Cell>
        </DataTable.Row>
      </DataTable>
    </View>
  );
}
