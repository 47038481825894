import React from "react";
import { Text } from "react-native";
import { Button, IconButton, useTheme } from "react-native-paper";
import { useBankStatusesQuery, useUnlinkBankAccountMutation } from "../../generated/graphql";
import View from "../ui/View";

export default function RenewNeeded() {
  const theme = useTheme();
  const { data } = useBankStatusesQuery();
  const [unlinkBankAccount] = useUnlinkBankAccountMutation()
  console.log(data);

  if (!data) {
    return <Text>Loading...</Text>;
  }

  return (
    <View>
      <Text style={{ color: theme.colors.error }}>
        Accès à vos comptes expirée
      </Text>
      {data?.currentUser.bankStatuses.map((bankStatus) => {
        return (
          <View style={{flex: 1, flexDirection: 'row'}}>
            <Text>{bankStatus.status}</Text>
            <IconButton icon="delete" onPress={
                () => {
                    unlinkBankAccount({
                        variables: {
                            budgetInsideClientId: bankStatus.id
                        }
                    })
                }
            }/>
          </View>
        );
      })}
    </View>
  );
}
